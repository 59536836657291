<template>
  <div class="profile">
    <advertisement-top-banner-text />
    <div class="container">
      <b-row>
        <b-col md="6">
          <template v-if="$options.components['profile-show-edit-profile']">
            <profile-show-edit-profile />
          </template>
        </b-col>
        <b-col md="6">
          <template v-if="$options.components['profile-my-account-detail']">
            <profile-my-account-detail />
          </template>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "profile",
  data() {
    return {
      first: "",
      last: "",
      phone: "",
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.userInfo,
    }),
  },
  async mounted() {
    await this.$store.dispatch("profile/getCustomerData", this.userInfo._id);
  },
  methods: {},
};
</script>

<style scoped></style>
